const config = {
  apiGateway: {
    REGION: 'us-west-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://7ehkphkt47.execute-api.us-west-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://9sghm6wpnf.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://36zx4ciw36.execute-api.us-west-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.12.3',
    HOSTNAME: 'https://locations.staging.pas.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.pas.forwoodsafety.com',
    WEBSOCKET: 'wss://ko2233x0k2.execute-api.us-west-2.amazonaws.com/staging'
  },
};

export default config;